.maindiv{
  /* width:85%; */
    width:100%;
    padding-top: 6rem;
    /* background-color: yellow; */
    margin-inline: auto;
    color:#0F0B3E;
    /* color:red; */
    /* position: relative; */
    /* min-height: 100vh; */
}
@media(min-width:1800px) {
 
  .maindiv{
    width:60%;
  }
}


.seconddiv{
  width:85%;
  margin-inline:auto;
}

@media(max-width:768px) {
  .seconddiv{
      width:95%;
  }
}

.section1maindiv{
   position: relative;  
   width:90%;
   margin-inline:auto
  /* overflow:hidden; */
}

@media(max-width:768px) {
  .section1maindiv{
    width:95%;
  }
}




/* @media(min-width:1800px) {
  .maindiv{
    width:50%;
  }
} */
 
.leftarr{
  position: absolute;
  cursor:pointer;

  left:0;  

}
.rightarr{
  position: absolute;
  cursor:pointer;
  right:0;

}

.leftarr2{
  position: absolute;
  cursor:pointer;
  right:4rem;  
  width:3rem;
  bottom:-4rem;
  /* z-index:1000; */
  height:3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid black;
  border-radius: 50%;
}
.rightarr2{
  position: absolute;
  cursor:pointer;
  right:0;
  bottom:-4rem;
  width:3rem;
  height:3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid black;
  border-radius: 50%;
  

}

@media(max-width:768px) {
  .leftarr{
    left:-0.5rem
  }
  .rightarr{
    right:-0.5rem;
  }
}





.toggleContainer{
    display:flex;
    gap:1rem; 
    margin-inline:auto;
    overflow:hidden;
    width: 95%;

}

@media(max-width:768px) {
  .toggleContainer{
    gap:1.5rem;
  }

}


.option{
    color:#3F3D56;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 550;
    /* background-color:red; */
    padding-inline:1rem;
   
}

.option.active {
    color: #7065f0;
    border-bottom: 3px solid #7065f0;
  }


  .section1{
    /* padding:2rem 0 1rem; */
   
  
  
  /* margin-inline: auto; */
  background-color:white;
  /* box-shadow: 0 1px 2px 0 black; */
  height:76px;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  
 

  } 

  .section1stick{
      position: -webkit-sticky;
  position:sticky;
  padding-inline: auto;
  /* padding:2rem 0 0 0; */
 
  /* padding:1rem ; */
  height:76px;
  display:flex;
  align-items: last baseline;
  justify-content: center;
 

  top:0;
  z-index:999;

  background-color: #FAFAFE;
  /* background-color: red; */
 border-bottom: 1px solid #E1E1E1;
 /* box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25); */

  }

  .section2{
    padding:2rem 0 1rem;
  }

  .section2main{
  
    display: flex;
    flex-direction: column;
    gap:2rem;
    align-items: center;
  }

  .section2intro{
    display:flex;
    justify-content: space-between;
    gap:2rem;
    flex-wrap: wrap;
    /* background-color: black; */
    width: 100%;
  }

  .section2heading{
    display: flex;
    align-items: center;
    gap:1rem;
    flex-wrap:wrap
  }

  .section2buttondiv{
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
  }

  .section2imgdivp{
    width: 100%;
    padding:2rem;
    background-color: #F6F6F6;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    /* overflow:"auto"; */
  }
  @media(max-width:768px) {
    .section2imgdivp{
      padding:1rem 0.5rem;
    }
  }
  .section2imgdiv1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:1rem;
    overflow:auto;

    padding:1rem;
    width:100%; 
    /* height:7rem; */

    border: 1px solid #E3E3E3;
    background: #D9D9D9;

    border-radius: 12px;

    overflow: auto;
    /* overflow:hidden; */
    
  }
 



  .rowimg{
    width:10%;
  }

.section2imgdiv2{
    display: flex;
    align-items: center;
    gap:1rem;
    width:100%;
    /* flex-wrap: wrap; */
    justify-content: center;
    position: relative;
}

.launchexpbtndivp{
  position:absolute;
  width:100%;
  height:100%;
  background-color: #000000;
  opacity:0.5;
  z-index: 1;
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.section2imgdiv3{
 display: flex;
 align-items: center;
 width:100%;
 justify-content: space-between;
 gap:3rem;
 flex-wrap: wrap;
 
}

.section2descriptionp{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap:1rem;
    /* flex-wrap:wrap; */
    width:100%;
    
}

@media(max-width:700px){
  .section2imgdiv2{
    flex-direction: column;
}
}

@media(max-width:1000px){
  .section2descriptionp{
    flex-wrap:wrap;
  }

  
}

.section2description{
    border:1px solid #E3E3E3;
    border-radius: 10px;
    width:100%;
    /* height:10rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding:2rem 2rem 1rem;
}



.section2features{
    border:1px solid #E3E3E3;
    border-radius: 10px;
    width:100%;
    /* height:10rem; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding:2rem 2rem 1rem;
    overflow:auto;
}
@media(max-width:768px) {
  .section2description, .section2features{
    padding:1rem 0.5rem;
  }
}

.section3{
  padding:2rem 0 1rem;
}
  
.section4{
  padding:2rem 0 1rem;
    border-bottom: 1px solid #E3E3E3;
}
.section5{
  padding:2rem 0 1rem;
}

.section6{
  padding:2rem 0 1rem;
  
}
.section7{
  padding:2rem 0 1rem;
  
}
.section8{
  padding:2rem 0 1rem;
  
}
.section9{
  padding:2rem 0 1rem;
  
}
.section10{
  padding:2rem 0 1rem;
  
}
.section11{
  padding:2rem 0 1rem;
  
}
.section12{
  padding:2rem 0 2rem;
  
}

.inputdiv{
  border: 1px solid #2F2D48;
  border-radius: 8px;


  padding: 1rem;
}
  

.FeaturedPropertyContainer {
  /* padding-inline: 5.5rem; */
  padding-top:3rem;
  overflow:auto;
  width:100%;
  /* margin-left:3rem; */
  /* padding-inline:4rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  
  /* justify-content: space-between; */
  gap:2rem;
  /* margin-inline: 4rem; */
}

h4{
  
  color:#0F0B3E;
  /* color:green; */
}

.section3grid{
  margin-top: 4rem;
  width: 95%;
  margin-inline: auto;
 display: grid;
 grid-template-columns: repeat(2,1fr);
 row-gap:4rem;
 column-gap:1rem;
 margin-bottom: 4rem;
}

@media(max-width:1024px){
  .section3grid{
    grid-template-columns: repeat(1,1fr); 
    row-gap:2rem; 
    margin-bottom: 2rem;  
  }
} 


.section2grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  /* gap:1rem; */
  overflow: auto;;
  
}

.section2imgdivbuttondivp{
  display: flex;
  align-items: center;
  gap: 2rem;
}



@media(max-width:300px) {
  .section2imgdivbuttondivp{
    gap:1rem;
    flex-wrap: wrap;
  }
}

.profilebuttondivp{
  display: flex;
  align-items: center;
  gap: 5rem;
  background-color: rgba(112, 101, 240, 0.12);
  padding: 0.6rem 1rem 0.6rem 0.6rem;
  border-radius: 10px;
  flex-wrap: wrap;  
}

@media(max-width:506px) {
  .profilebuttondivp{
    gap:1rem;
  }
}

.desc{
  
  width: 80%;
}

@media(max-width:800px) {
  .desc{
    width:100%;
  }
}

.formgrid{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap:2rem;
  row-gap: 2rem;
}




@media(max-width:530px) {
  .formgrid{
      grid-template-columns: repeat(1,1fr);
  }
}
















.openButton {
  /* Style for your button */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  z-index:999;
}

.modalContent {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.closeButton {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeButton:hover,
.closeButton:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.btn{
   background-color: transparent;
  border: none;
  color: #7065f0;
  white-space: nowrap;
  font-size: 0.9rem;
  cursor: pointer;
  /* transition: all 0.5s linear;  */
}

.btn:hover{
  transform:scale(1.1);
  transition: all 0.1s linear;
}

.btnclicked{
  padding: 1rem 2.5rem;
  width: fit-content;
  border-radius: 10px;
  border: none;
  background-color: #7065f0;
  color:white;
  font-size: 0.9rem;
  cursor:pointer;

  transition: background-color 0.5s ease-in; 

}

@media(max-width:768px) {
  .btnclicked{
    padding:1rem 1.5rem;
  }
}

.launchexpdivhover{
  width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    /* display: visible; */
  
}
.launchexpdivhovernot{
  width: 0%;
    height: 0%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display:none; */
  transition:all 0.5s ease-in-out;
}

.launchexpbtn {
  display: flex;
  position: absolute;
  z-index: 2;
  background-color: #7065f0;
  padding: 0.8rem 2.5rem;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  opacity: 1;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}


@media(max-width:768px) {
  .launchexpbtn {
  padding:0.5rem 1rem;
  border-radius: 5px;
}
}


.launchexpbtnnot{
  /* background-color: transparent; */
  /* transition: all 1s ease-in-out; */
}


/* .imgtransformdiv:hover{
  transform:scale(1.2);
  transition-duration: 0.3s;
} */

.imgtransformdiv{
  /* min-width:6rem; */
  width:10%;
  aspect-ratio: 1.5;
  overflow:hidden;
  border-radius: 5px;
  cursor:pointer;
  background:white;
  
}

@media(max-width:768px) {
  .imgtransformdiv{
    min-width:5rem;
  }
}






.section12form_grid{

  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap:2rem;
}

@media(max-width:550px) {
  .section12form_grid{
    grid-template-columns: repeat(1,1fr);
  }
}
.ogimg{
  cursor:pointer;
  transition:all 0.5s ease-in-out;
}

.ogimg:hover{
  transform:scale(1.5);
}

.section12stardivp{
  display: flex;
  align-items: center;
  gap:0.5rem;
  margin-top: 1rem;
}

.section12stardiv{
  width:3rem;
  height: 3rem;
  border-radius: 50%;
  background: #7065F01A;
  border: 1px solid #F9F9F9;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}



.reviewcardcontainer{
  margin-top: 3rem;
  display: flex;

  flex-direction: row;
  width:100%;
  justify-content: space-between;
  /* align-items: stretch; */
  /* gap:1rem; */
  overflow:auto;
 

}










.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  
  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #666;
    font-size: 1.25rem;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  
  .closeButton:hover {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .modalTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0F0B3E;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .stepIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #E3E3E3;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .step.active {
    background-color: #7065f0;
    color: white;
  }
  
  .stepConnector {
    height: 2px;
    width: 80px;
    background-color: #E3E3E3;
    margin: 0 10px;
  }
  
  .formSection {
    margin-bottom: 1.5rem;
  }
  
  .formSection label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .currencySymbol {
    position: absolute;
    left: 1rem;
    color: #333;
    font-weight: 500;
  }
  
  .inputField {
    width: 100%;
    padding: 1rem;
    padding-left: 2rem;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .inputField:focus {
    outline: none;
    border-color: #7065f0;
  }
  
  .minInvestment {
    margin-top: 0.5rem;
    font-size: 0.85rem;
    color: #666;
  }
  
  .investmentSummary, .investmentProjection {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
  }
  
  .investmentSummary h4, .investmentProjection h4 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #0F0B3E;
  }
  
  .summaryItem, .projectionItem {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  .summaryTotal, .projectionTotal {
    font-weight: 600;
    color: #0F0B3E;
    border-top: 1px solid #E3E3E3;
    margin-top: 0.5rem;
    padding-top: 1rem;
  }
  
  .paymentMethods {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .paymentMethod {
    flex: 1;
    padding: 1rem;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .paymentMethod:hover {
    border-color: #7065f0;
  }
  
  .paymentMethod.selected {
    border-color: #7065f0;
    background-color: rgba(112, 101, 240, 0.05);
  }
  
  .paymentIcon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .paymentIcon img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .bankDetails, .cardDetails {
    margin-top: 1.5rem;
  }
  
  .infoBox {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    color: #666;
  }
  
  .infoBox svg {
    color: #7065f0;
    flex-shrink: 0;
    margin-top: 3px;
  }
  
  .formRow {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .formGroup {
    flex: 1;
  }
  
  .formGroup label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #333;
  }
  
  .termsAgreement {
    margin-bottom: 1.5rem;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    font-size: 0.9rem;
    color: #666;
    cursor: pointer;
  }
  
  .checkbox {
    margin-top: 3px;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .backButton {
    padding: 0.75rem 2rem;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    background-color: white;
    color: #666;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .backButton:hover {
    background-color: #f0f0f0;
  }
  
  .successContent {
    text-align: center;
    padding: 1.5rem 0;
  }
  
  .successIcon {
    margin-bottom: 1.5rem;
  }
  
  .successContent h3 {
    font-size: 1.5rem;
    color: #0F0B3E;
    margin-bottom: 1rem;
  }
  
  .successContent p {
    color: #666;
    margin-bottom: 0.75rem;
    line-height: 1.5;
  }
  
  @media (max-width: 576px) {
    .modalContent {
      padding: 1.5rem;
    }
    
    .formRow {
      flex-direction: column;
      gap: 1rem;
    }
    
    .paymentMethods {
      flex-direction: column;
    }
    
    .buttonContainer {
      flex-direction: column-reverse;
    }
  }
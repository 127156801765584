.maindiv{
    width:85%;
    
    margin-inline:auto;
    display: flex;
    flex-direction: column;
    gap:2rem;
    /* align-items: center; */
    
}

.heading{
    text-align: center;
    color:#37393F;
}

.imgdiv{
    background-color: #7065f0;
    padding:1rem;
    border-radius: 50%;
}

.builderContainer{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    gap:2rem;
    /* overflow:auto; */
}

@media(max-width:535px) {
    .builderContainer{
       
        grid-template-columns: repeat(1,1fr);
     
    }
}

@media(min-width:536px) and (max-width:820px) {
    .builderContainer{
       
        grid-template-columns: repeat(2,1fr);
     
    }
}
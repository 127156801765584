.maindiv{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.slider{
    height:5px;
    position: relative;
    background-color: #ddd;
    border-radius: 5px;
}

.progress{
    position: absolute;
    height:100%;
    /* left:71%;
    right:0%; */
    background-color: #7065f0;
    border-radius: 5px;
}

.rectangle1{
    position:absolute;
    background-color: #7065f0;
    padding: 0.5rem;;
    border-radius: 5px;
    top:-3rem;
    width:fit-content;
    color:white;
    font-size: 0.75rem;
   

}
.rectangle2{
    position:absolute;
    background-color: #7065f0;
    padding: 0.5rem;;
    border-radius: 5px;
    top:-3rem;
    width:fit-content;
    color:white;
    font-size: 0.75rem;
      
}

.rangeinput{
    position:relative;
}

.slider input{
    position:absolute;
    width:100%;
    left:0;
    height:5px;
    /* top:-5px; */
    background:none;
    pointer-events: none;
    appearance: none;
}

.slider input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none;
    appearance: none;
    height:15px;
    width:15px;
    background-color: #7065f0;   
    border-radius: 50%;
    pointer-events: auto;
    cursor: pointer;
}


/* Firefox */
.slider input[type="range"]::-moz-range-thumb{
    -moz-appearance: none;
    appearance: none;
    height:15px;
    width:15px;
    background-color: #7065f0;       
    border-radius: 50%;
    pointer-events: auto;
    cursor: pointer;
}


/* Edge and IE */
.slider input[type="range"]::-ms-thumb{
    -ms-appearance: none;
    appearance: none;
    height:15px;
    width:15px;
    background-color: #7065f0;     
    border-radius: 50%;
    pointer-events: auto;
    cursor: pointer;
}


.inputp{
    display: flex;
    justify-content: space-between;
    gap:0.2rem;
    flex-wrap: wrap;
    /* -moz-appearance: none; */
}

input[type="number"]::-webkit-outer-spin-button{
    -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
}

.input{
    border:1px solid #B1AEAE;
    padding:0.5rem;
    width:48%;
    -moz-appearance: textfield;
}
.desc{

  font-weight: 400;
  font-size: 0.9rem; 
  line-height: 1.2rem;
  width: 80%;

  }
  
  @media(max-width:800px) {
    .desc{
      width:100%;
    }
  }


  .carddivp{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:1rem;
    margin-top: 2rem;
    overflow: auto;
  }

  @media(max-width:650px) {
    .carddivp{
        grid-template-columns: repeat(1,1fr);

    }
  }
  .card{
    background-color: #F6F6F6;
    border:1px solid #E1E1E1;
    display:flex;
    flex-direction: column;
    gap:2rem;
    align-items: flex-start;
    border-radius: 10px;
    padding:2rem;
  }

  .cardintro{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .cardlocationdiv{
    display: flex;
    align-items: center;
    gap:0.5rem;
    flex-wrap: wrap;
  }

 

  

  .cardheading{
    font-size: 1.3rem;
  }

  .carddesc{
    font-size: 0.9rem;
    color:#999999;
  }

  .cardcapsule{
    display: flex;
    align-items: center;
    gap:0.5rem;
    border:1px solid #E1E1E1;
    border-radius:999px;
    background-color: white;
    padding: 0.5rem;
    /* flex-wrap: wrap; */
    
  }



  .logodiv{
    width:0.8rem;
  }

  


.maindiv{
  display: flex;
 
  height: 100%;
  width: 100%;
  margin-inline:auto;
  
  
 /* overflow-y: hidden; */
 font-family: "Urbanist", sans-serif;
}

/* @media(min-width:1800px) {
.maindiv{
  width:80%;
}
} */



.bannerimage{
  border-radius: 20px 100px 100px 20px;
  background-color: rgba(112, 101, 240, 0.06);
  /* background-color: red; */
  width: 50%;
  max-height: 100%;
  margin: 2%;
  box-sizing: border-box;
  overflow: hidden;
  position:relative;

  
}


.homeimg{
/* left:0;
right:0; */
width:100%;
height: 80%;
position: absolute;
bottom: 5%;

}



.blurdiv{
   left:2%;
   right:2%;
   height:40%;
   bottom: 2%;
   display:flex;
 
   
   align-items: center;
   padding-left: 10%;     
  border-radius: 40px 444px 444px 40px;
  backdrop-filter: blur(10px);
  color: white;
  
  position: absolute; 
  background-color: rgba(0, 0, 0, 0.13)
}



.signup-page {
  display: flex;
  flex-direction: column;
  margin: auto auto;
  width: 50%;
  margin-inline:auto;
  /* padding: 30px; */
  padding-inline:3rem;
  padding-right:5rem;
  /* padding:0 10rem 0 3rem; */
  /* align-items: center; */
  /* background-color: red; */
  gap:2rem;
  border-radius: 5px;
}


.blur-background {
  filter: blur(4px);
  transition: filter 0.3s ease;
}

.headingRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.language{
 display: flex;
 align-items: center;
 justify-content: center;
}

.languageButton{
  color: rgba(112, 101, 240, 1);
  background-color: rgba(226, 233, 255, 1); 
  border: 0px;
  padding: 0.7em;
  border-radius: 10px;
  cursor:pointer;
}

.header{
  /* margin-top: 2em; */
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     font-weight: 500;
     gap:0.3rem;
     /* margin-bottom: 1em; */
     line-height: 1.5;
}

.logo{
  font-size: 2rem;
  font-weight: 600;
  color: rgba(0, 13, 59, 1);
  /* margin-bottom: 0.5rem; */
}

.welcome-title{
  font-size: 15px;
  color: rgba(152, 152, 152, 1);
  /* margin-bottom: 2em; */
}

.name-inputs{
  display: flex;
  flex-direction: column;
  color: rgba(114, 114, 114, 1);
  font-size: 18px;
  font-weight: 400;
  width: 50%;
  line-height: 1.5;
}

.nameInput{
  border-radius: 10px;
  width:100%;
  /* color:#D2D2D2; */
  border-color: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 1rem;
  padding: 0.7em;
  /* margin-bottom: 4em; */
  /* margin-top: 0.2rem; */
}

.last-name-inputs{
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  color: rgba(114, 114, 114, 1);
  font-size: 18px;
  font-weight: 400;
  width: 50%;
  line-height: 1.5;
}

.signup-form{
  display: flex;
  flex-direction: column;
  align-items: start;
  gap:4rem;
  /* margin-bottom: 1rem;; */
}

.namefield{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.inputPhone{
  display: flex;
  flex-direction: column;
  width:100%;    
  gap:1rem;
  color: rgba(114, 114, 114, 1);
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
}
 

.registerPhone{
  display: flex;
  flex-direction: column;
  gap:1rem;
  width:100%;
}
.terms-checkbox{
  font-size: 15px;
  font-weight: 400;
  /* text-decoration: underline; */
  /* margin-bottom: 1rem; */
  display: flex;
  align-items: center;
  gap:0.5rem;
}

.checkedField{
  width: 15px;
  cursor:pointer;    

}
.register-button{
  width: 100%;
  background-color: rgba(112, 101, 240, 1);
  color: white;
  padding: 1em;
  border-radius: 10px;
  border: 0px;
  /* margin-bottom: 1rem; */
  font-weight: 500;
  font-size: 1rem;
  cursor:pointer;
}

.login-link{
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin-top: 1rem;;
  
  text-decoration: none;
  color: rgba(89, 89, 89, 1);
  /* margin-bottom: 2em; */
}
.social-login{
  
 
  width: 100%;
}

.google-login{
  width: 100%;
  padding: 0.7em;
  border-radius: 10px;
  border: 1.5px solid rgba(35, 50, 104, 0.39);
  display: flex;
  align-items: center;
  justify-content: center;
  gap:0.3rem;
  background-color: white;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  cursor:pointer;
}

.google-logo{
  width: 20px;
  height: auto;
 
}

@media(max-width:600px) {
  .blurdiv{
    display: none;
  }
  .homeimg{
    display:none

  
  }
}


@media(max-width:1100px) {
  .signup-page{
    
    width:100%;
    padding-right: 0;
    padding-inline:1rem;
  }
  .maindiv{
    flex-direction: column;
    gap:2rem;
    height:auto;
    width: auto;
  }

  .homeimg{
    position:static;
    width:100%;
  height: auto;
  margin-inline:auto;

  
  }

  .bannerimage{    
    
    width: 90%;
    margin-inline:auto;
    
}


  
}


.maindiv{
    width:100%;
    /* height:100vh; */
    aspect-ratio:2;
    position:relative;
    background-color: white;
   
}

.section1{
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* padding-left:5%; */
    width:45%;
    position:absolute;
    background-color: rgba(255, 255, 255, 0.8);
    /* background:red; */
   
    z-index:0;
    
    align-items: center;
   top:0;
   bottom:0;    
    left:0;
}
.introdiv{
    display: flex;
    flex-direction: column;
   /* align-items: center; */
    gap: 1.5rem;  
    width:70%;
    /* background-color: yellow;;   */
}
.para{
    color:#999999;
    font-family: Inter;
    /* width:60%; */
    text-align:left;
    /* background-color: yellow; */
}
@media(max-width:870px) {
    .section1{
        position: static;
        width:100%;
        
    }

    .introdiv{
        align-items: center;
       
    }
    .maindiv{
        position:static;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap:2rem;
    }
    .section2{
        position:relative;
    }

    .headingdivp{
        /* background:yellow; */
        text-align: center;

    }
    .para{
        margin-inline:auto;
        width:80%;
        /* background-color: yellowgreen; */
        text-align:center;
    }
}
@media(max-width:1000px) {
    .introdiv{
        padding:3rem 0 0 0;
        width:90%;
    }
    .section1{
        align-items: start;

    }
}

.headingdivp{
    /* line-height: 1; */
    /* width:50%; */
    
    /* background:yellow; */
}

.heading{
    /* color: #000000; */
    
    font-size: 3rem;
    font-weight: 800;
    line-height:1;
    white-space: wrap;
    width:100%;
    
}



.section2{
    min-width:100%;
    min-height:100%;
}
.maindiv{
    display: flex;
    flex-direction: column;    
    padding:2rem;
    background-color: #F6F6F6;
    border:1px solid #E1E1E1;    
    border-radius: 12px;
    /* height:100% !important; */
    gap:1rem;    

    /* margin-bottom: 2rem; */
    /* max-height: fit-content;    */
    transition:all 0.3s ease-in-out;
    
    
}

.maindiv:hover{
    box-shadow: 0px 4px 4px 0px #00000040;
   

}

/* .maindiv:hover{
    transform:scale(1.05);  
    transition: all 0.3s ease-in-out; 
} */

.imagediv{
    width:100%;
    /* height:200px; */
    aspect-ratio: 4/3;
    overflow:hidden;
    cursor:pointer;
}

.ogimg{
    transition: all 0.5s ease-in-out;;
}

.ogimg:hover{
    transform:scale(1.1);
 
}



.wishlist_btn{
    position: absolute;
    top:0.5rem;
    right:0.5rem;
    width:2rem;
    aspect-ratio: 1;
    background-color: #7065f0;
    border-radius: 5px;  
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
}

.wishlist_btn:hover{
    border-radius: 50%;
    transition: all 0.3s ease-out;
}

.wishlist_btn:hover{
    transform: scale(1.1);
}

.imagediv{
    border-radius: 15px;    
    position: relative;
    width: 100%;
    align-self: center;
}

.description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:0.5rem;
    /* margin-left: 1rem; */
    /* line-height: 1.5; */
}
.heading{
    font-size: 1.2rem;
    font-weight: 700;

}
.subheading{
    font-size: 0.9rem;
    font-weight: 550;
  
}

.details{
    display: flex;
    flex-direction: row;
    padding-block: 0rem;
    /* justify-content: space-between; */
    gap:0.5rem;
    /* flex-wrap: wrap; */
    /* margin-left:1rem; */
    /* gap:0.5rem; */
}

.capsule{
    background-color: rgba(234, 234, 234, 1);
    border-radius: 15px;
    display: flex;
    /* justify-content: space-between; */
    gap:0.5rem;
    align-items: center;
    padding: 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;

    
}

.pricediv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap:3rem; */
    /* flex-wrap: wrap; */
    
    /* gap:4rem; */

    /* margin-left:1rem; */
}


/* @media(max-width:450px) {
    .details{
        flex-wrap:wrap;
    }
    .pricediv{
        gap:0rem;
    }
} */
/* @media(max-width:490px) {
    .details{
        flex-wrap:wrap;
    }
    .pricediv{
        gap:1rem;
    }
} */

.pricesection{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.propertyButton{
     
}
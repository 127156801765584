.maindiv{
    position: fixed; 
    top:0;
    left:0;
    width:100%;
    height: 100%;
    backdrop-filter: blur(4px);
    display:flex;
    justify-content: center;
    align-items: center;
    /* padding-inline:0.5rem; */
}
/* @media (max-width:1462px) {
    .seconddiv{
        padding:2rem;
    padding-block: 3rem;
    }
} */
.seconddiv{
    display:flex;
    flex-direction: column;
    /* height:20rem;
    width:50rem; */
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 3px 3px 0px black;
    padding-block:2rem;
    /* padding-block: 2rem; */
    
    
    position: relative;
}
.thankyouseconddiv{
    display:flex;
    flex-direction: column;
    /* height:55%;
    width:30%; */
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 3px 3px 0px black;
    padding:2rem;
    padding-block: 4rem;
    
    
    position: relative;
}

.searchText{
  
    margin-bottom:0.5rem;
  

width: 90%; 

height:0rem;

border: none;
background-color: #efecff;
border-radius: 0.3rem;

padding: 1rem;
}

.searchText1{
    

   

width: 90%; 

margin-bottom:0.2rem;


border: none;
background-color: #efecff;

padding: 1rem;
}

.griddiv{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    /* gap: 0rem; */
}
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700&family=Outfit&display=swap");

.maindiv {
  font-family: Urbanist;
  width: 100%;

  /* height: 100vh; */

  color:#0F0B3E;
  margin-inline: auto;
  /* background-color: black; */
  padding-bottom: 2rem;

}
.seconddiv{
  width:85%;
  margin-inline: auto;
  padding-top: 6rem;
}
@media(min-width:1800px) {
  .maindiv{
      width:60%;
  }

}
@media(max-width:768px) {
  .seconddiv{
      width:95%;
  }
}
.mainheadingdiv{
  padding-top: 0;

}

.mainHeading {
  color: #ffffff;

  font-size: 4.5rem;
  font-weight: 650;
  letter-spacing: 0em;
  text-align: center;
  
}



.subHeading {
  font-family: Outfit;
  font-size: 1.1rem;
  font-weight: 400;
  /* line-height: 1.4em; */
  letter-spacing: 0em;
  margin-inline: auto;
  text-align: center;
  color: #ffffff;
  width:70%;
  /* margin-top: -4rem; */
  
}
@media(max-width:500px) {
  .mainHeading{
    font-size: 3.5rem;
    font-weight: 650;
  }
  .subHeading{
    font-size: 1.2rem;
    font-weight: 500;
    width:80%;
  }
}

/* hello section */

.HelloSection {
  position: relative;
  /* background: #7065f0; */
  background-image: url('./banner_img.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* background-attachment: fixed; */
   background-color: #7065f0;  /*fallback color */
  /* background-blend-mode: overlay; */
  display: flex;
  justify-content: center;
  padding-top:76px;
}

@media(max-width:768px) {
  .HelloSection{
    /* background-image: none; */
    /* background-size: cover; */
    padding-top: 0;
  background-position-x: center;
  background-position-y: 76px;
  }
}


.helloContentContainer {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background:black; */
  gap: 5rem;
  padding: 12rem 1rem 1rem;
}
@media(max-width:1200px) {
  .helloContentContainer{
    width:100%;
  }
}

.optionTooglerDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background: black; */
  align-items: center;
  
}
.searchContainer {
  
  width: 88%;
  margin-bottom: 2rem;
  /* padding:2rem 0; */
}


@media(max-width:1023px) {
  .searchContainer{
    width:100%;
  }
}

/* search and exprole section */


.home1{
  display: flex;
  width: 100%;
  justify-content: space-between;
}



.searchSection {
  display: flex;
  flex-direction: column;
  gap:3rem;
  /* flex-wrap: wrap; */
  /* margin-top: 6rem; */
  margin-left:0rem;
  /* background-color: black; */
  /* width:50%; */
  /* margin-inline: auto; */
  
  /* padding-inline:4rem; */
  /* width:85%; */
  justify-content: space-between;
  /* margin-inline: auto; */
  /* gap:10rem; */
  /* align-items: center; */
}

.searchSectionIntro {
  display: flex;
  flex-direction: column;
  
  /* margin-inline: 3em; */
  /* width: 50%; */
  gap: 1rem;
}

.searchSectionHeader {
  font-weight: 650;
  font-size: 2rem;
}
.searchSectionDesc {
  /* font-weight: 500; */
  font-size: 0.9rem;
    font-weight: 400;
    width:70%;
  line-height: 1.2rem;
}
.searchInputContainer {
  /* margin-inline: 3em; */
  /* margin-top: 2rem; */
  
  position: relative;
  width: 40%;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  padding: 1rem;
  /* width: 20%; */
}

.searchInput {
  
  
  border:none;
  outline: none;
  background-color: rgba(244, 244, 244, 1);
  /* margin-right: 0.5em; */
  
}
.searchResults {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  
  gap:1.5rem;
  margin-right: 1rem;
  overflow:auto;
  width:80%;
  
  /* background-color: red; */
  
  
  
  /* justify-content: start; */
}
@media(max-width:768px) {
  
  .searchResults{

    margin-right: 0rem;
    
    grid-template-columns: repeat(1,1fr);
  }
}

.Search_Properties {
  
  position: relative;
  overflow: hidden;
  aspect-ratio: 1.5;
  width:100%;
  /* width: 20rem;
  height: 15rem; */
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

/* @media(max-width:1150px) {
  .Search_Properties {
    height:100%;
  }
} */
/* @media(max-width:768px) {
  .Search_properties{
    aspect-ratio:1.2;
  }
} */

.searchProperty {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  z-index: 2;
  
  text-align: center;
}
.propertyTitle {
  font-size: 1.6rem;
  font-weight: 650;
  /* margin-bottom: 0.7rem; */
}
.propertydesc {
  
  padding: 1em;
  font-size: 0.75em;
  font-weight: 500;
  margin-bottom: 2rem;
  line-height: 1.2rem;

  
}
/* @media(max-width:300px) {
  .propertydesc{
    display: none;
  }
} */
.propertyImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}
.searchPropertyBg {
  content: "''";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(112, 101, 240, 0.8);
  z-index: 1;
}



/* featured Properties section */

.FeaturedIntroContainer {
  
  margin-top: 6rem;

 

}
.introContainer {
  display: flex;
  flex-direction: column;
  /* margin: 3em; */
  
  gap: 0rem;
}
.FeaturedSectionHeader {
  font-weight: bolder;
  font-size: 2rem;
}
.FeaturedSectionDesc {
  
}
@media(max-width:590px) {
  /* .searchSectionIntro{
    align-items: center;
  } */
  .FeaturedSectionDesc{
    width:90%;
  }
  .searchSectionDesc {
    /* font-weight: 500; */
    
    
      width:90%;
    
  }
  .searchInputContainer {
    width:100%;
  }
}
.FeaturedButtonContainer {
  /* margin: 3em; */
}
.FeaturedButton {
  /* width:10rem; */
  text-align: center;
  padding:0.8rem 1rem;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
  color:#0F0B3E;
  background-color: #EAEAEA;
  cursor: pointer;
  font-size: 0.9rem;
}




.builderContainerp{ 
  margin-top: 6rem;  
}

.builderContainer{
  /* padding: 3.5rem; */
  display: flex;
  align-items: center;
  width: 100%;
  overflow:auto;
  /* justify-content: center; */
  margin-top:3rem;
  gap:2rem;
  /* margin-left: 3rem; */
}

.AIintroContainer{
  
  margin-top: 6rem;
  
  
}

.AISectionHeader {
  font-weight: bolder;
  font-size: 2rem;
}

.AISectionDesc {
  font-weight: 400;
  font-size: 0.9rem;
  /* width: 50%; */
  line-height: 1.2rem;
}



@media(max-width:1023px){
  .home1{
  flex-direction: column;
  align-items: center;
  gap:4rem;
}
.searchSection{
  align-items: center;
}
/* .searchSectionDesc{
  width:100vw;
} */

}

.desc{
  font-weight: 500;
  font-size: 0.9rem; 
  line-height: 1.2rem;
  color:#525252;
  
  width: 80%;
}

@media(max-width:800px) {
  .desc{
    width:100%;
  }
}


.cardslidercontrolldiv{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrowcontainerdivp{
  display: flex;
  align-items: center;
  gap:1rem;
}

.arrowdiv{
  cursor:pointer;
  border:1px solid black;
  padding:0.5rem;
  border-radius: 50%;
}

.rotate180{
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -moz-transform: rotate(180deg);    /* Firefox */
  -ms-transform: rotate(180deg);     /* Internet Explorer 9 */
  -o-transform: rotate(180deg);      /* Opera */
  transform: rotate(180deg);
  /* Optional: add transition for smooth rotation */
  transition: transform 0.5s ease-in-out;
}

.marginRight{
  margin: 0 50px;
}





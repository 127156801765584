.maindiv {
    width: 100%;
    height: auto;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 24px rgba(15, 11, 62, 0.12);
    border-radius: 16px;
    background: #fff;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .maindiv:hover {
    transform: translateY(-6px);
    box-shadow: 0px 12px 30px rgba(15, 11, 62, 0.16);
  }
  
  .imagediv {
    position: relative;
    height: 220px;
    width: 100%;
    overflow: hidden;
  }
  
  .ogimg {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    transition: transform 0.4s ease;
  }
  
  .maindiv:hover .ogimg {
    transform: scale(1.05);
  }
  
  .wishlist_btn {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    transition: transform 0.2s ease, background-color 0.2s ease;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .wishlist_btn:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 1);
  }
  
  .investmentBadge {
    position: absolute;
    bottom: 12px;
    left: 12px;
    background-color: rgba(10, 118, 71, 0.9);
    color: white;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    z-index: 3;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .description {
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .heading {
    font-weight: 600;
    font-size: 1.25rem;
    color: #0F0B3E;
    line-height: 1.3;
  }
  
  .subheading {
    font-weight: 400;
    font-size: 0.9rem;
    color: #616161;
    line-height: 1.5;
  }
  
  .investmentDetails {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    background-color: #f8f9fa;
    border-top: 1px solid #eaecef;
    border-bottom: 1px solid #eaecef;
  }
  
  .investmentItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .investIcon {
    color: #0a7647;
    font-size: 1.2rem;
  }
  
  .investData {
    display: flex;
    flex-direction: column;
  }
  
  .investLabel {
    font-size: 0.7rem;
    color: #666;
  }
  
  .investValue {
    font-weight: 600;
    font-size: 0.9rem;
    color: #0F0B3E;
  }
  
  .details {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    font-size: 0.8rem;
  }
  
  .capsule {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 0.8rem;
    background-color: #f0f2f5;
    padding: 6px 10px;
    border-radius: 20px;
  }
  
  .progressContainer {
    height: 6px;
    background-color: #e0e0e0;
    margin: 0 20px;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .progressBar {
    height: 100%;
    background-color: #0a7647;
    border-radius: 3px;
    transition: width 0.5s ease;
  }
  
  .deadlineInfo {
    display: flex;
    justify-content: flex-end;
    padding: 6px 20px 10px;
  }
  
  .progressText {
    font-size: 0.75rem;
    color: #f57c00;
    font-weight: 500;
  }
  
  .pricediv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px 20px;
    margin-top: auto;
  }
  
  .pricesection {
    display: flex;
    flex-direction: column;
  }
  
  .pricesection div:first-child {
    font-size: 0.8rem;
    color: #666;
  }
  
  .pricesection div:last-child {
    color: #0a7647;
    font-weight: 700;
  }
  
  .propertyButton button {
    background-color: #0a7647 !important;
    border: none !important;
    color: white !important;
    padding: 8px 16px !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    transition: background-color 0.2s ease !important;
  }
  
  .propertyButton button:hover {
    background-color: #085e39 !important;
  }
  
  @media screen and (max-width: 768px) {
    .investmentDetails {
      flex-direction: column;
      gap: 10px;
    }
    
    .investmentItem {
      width: 100%;
    }
  }
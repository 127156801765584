.container {
    width: 100%;
    padding: 2rem 0;
    color: #0F0B3E;
    border-bottom: 1px solid #E3E3E3;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .sectionTitle {
    font-size: 1.5rem;
    font-weight: 650;
    position: relative;
    display: inline-block;
    margin: 0;
  }
  
  .sectionTitle::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: #7065f0;
    border-radius: 2px;
  }
  
  .chatInfo {
    display: flex;
    align-items: center;
  }
  
  .participantsButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: rgba(112, 101, 240, 0.1);
    border: none;
    border-radius: 20px;
    color: #7065f0;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .participantsButton:hover {
    background-color: rgba(112, 101, 240, 0.2);
  }
  
  .chatContainer {
    background-color: #f8f9fa;
    border: 1px solid #E3E3E3;
    border-radius: 12px;
    overflow: hidden;
    height: 500px;
    display: flex;
    flex-direction: column;
  }
  
  .messagesContainer {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
  }
  
  .loadingContainer, .emptyStateContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666;
  }
  
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(112, 101, 240, 0.3);
    border-radius: 50%;
    border-top-color: #7065f0;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 1rem;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .chatStartMarker {
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  .propertyName {
    font-weight: 600;
    font-size: 0.9rem;
    color: #0F0B3E;
    margin-bottom: 0.25rem;
  }
  
  .chatPurpose {
    font-size: 0.8rem;
    color: #666;
  }
  
  .messageItem {
    display: flex;
    margin-bottom: 1.5rem;
    gap: 0.75rem;
  }
  
  .currentUserMessage {
    flex-direction: row-reverse;
  }
  
  .messageAvatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .messageAvatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .messageContent {
    max-width: 70%;
    background-color: #fff;
    border-radius: 12px;
    padding: 0.75rem 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .currentUserMessage .messageContent {
    background-color: rgba(112, 101, 240, 0.1);
  }
  
  .adminMessage .messageContent {
    background-color: rgba(112, 101, 240, 0.05);
    border-left: 3px solid #7065f0;
  }
  
  .messageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .messageSender {
    font-weight: 600;
    font-size: 0.9rem;
    color: #0F0B3E;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .adminTag {
    font-size: 0.7rem;
    font-weight: 500;
    background-color: #7065f0;
    color: white;
    padding: 0.15rem 0.4rem;
    border-radius: 3px;
  }
  
  .messageTime {
    font-size: 0.75rem;
    color: #999;
  }
  
  .messageText {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #333;
    word-break: break-word;
  }
  
  .messageInputContainer {
    padding: 1rem;
    background-color: #fff;
    border-top: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .attachButton, .sendButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #7065f0;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .attachButton:hover, .sendButton:hover {
    background-color: rgba(112, 101, 240, 0.1);
  }
  
  .attachButton:disabled, .sendButton:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .messageInput {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid #E3E3E3;
    border-radius: 20px;
    font-size: 0.9rem;
    transition: border-color 0.2s ease;
  }
  
  .messageInput:focus {
    outline: none;
    border-color: #7065f0;
  }
  
  .messageInput:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  .loginPrompt {
    text-align: center;
    padding: 0.5rem;
    background-color: #f8f9fa;
    font-size: 0.85rem;
    color: #666;
  }
  
  .loginPrompt a {
    color: #7065f0;
    font-weight: 500;
    text-decoration: none;
  }
  
  .loginPrompt a:hover {
    text-decoration: underline;
  }
  
  /* Participants List Styles */
  .participantsList {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 1.5rem;
  }
  
  .participantsList h4 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    color: #0F0B3E;
    text-align: center;
  }
  
  .participantsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .participantItem {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .adminParticipant {
    background-color: rgba(112, 101, 240, 0.05);
    border-left: 3px solid #7065f0;
  }
  
  .participantAvatar {
    position: relative;
  }
  
  .adminBadge {
    position: absolute;
    bottom: -2px;
    right: -2px;
    background-color: #7065f0;
    color: white;
    font-size: 0.6rem;
    padding: 0.1rem 0.25rem;
    border-radius: 3px;
  }
  
  .participantInfo {
    flex: 1;
  }
  
  .participantName {
    font-weight: 600;
    font-size: 0.9rem;
    color: #0F0B3E;
    margin-bottom: 0.25rem;
  }
  
  .participantInvestment {
    font-size: 0.85rem;
    color: #7065f0;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  
  .participantJoinDate {
    font-size: 0.75rem;
    color: #999;
  }
  
  .backToChatButton {
    display: block;
    margin: 1.5rem auto 0;
    padding: 0.75rem 1.5rem;
    background-color: #7065f0;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .backToChatButton:hover {
    background-color: #5a52c4;
  }
  
  @media (max-width: 768px) {
    .participantsContainer {
      grid-template-columns: 1fr;
    }
    
    .messageContent {
      max-width: 85%;
    }
  }
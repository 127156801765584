@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700&family=Outfit&display=swap");


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Urbanist;
     
    
}



/* For Chrome, Safari, and Edge */
*::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar width */
  height: 0px; /* Remove scrollbar height */
}

/* For Firefox */
* {
  scrollbar-width: none; /* Disable scrollbar */
}

/* For IE and Edge Legacy */
* {
  -ms-overflow-style: none; /* Disable scrollbar */
}

/* Optional: Hide scrollbar on macOS */
html {
  overflow: -moz-scrollbars-none;
}


/* @media(max-width:768px) {
     *{
        overflow:auto;
    } 
} */

html, body {
    margin: 0;
    height: 100%;
    scroll-behavior: smooth;
}

#root{
    margin: 0;
    height: 100%;
    width: 100%; 
}

/* @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .Appdiv{
    animation: fadeIn 1s forwards;
  } */


  @media (max-width: 1200px) {
    html {
      font-size: 15px;
    }
  }
  
  @media (max-width: 992px) {
    html {
      font-size: 14px;
    }
  }
  
    /*@media (max-width: 768px) {
    html {
      font-size: 13px;
    }
  }
  
 @media (max-width: 576px) {
    html {
      font-size: 12px;
    }
  }

  @media(max-width:300px) {
    html{
        font-size: 10px;
    }
  }
  @media(max-width:250px) {
    html{
        font-size: 8px;
    }
  }
  @media(max-width:150px) {
    html{
        font-size: 6px;
    }
  }
   */
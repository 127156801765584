.maindiv{
    
}

.grid{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    aspect-ratio: 1/0.4;
    margin-top: 4rem;
    overflow: auto;
}

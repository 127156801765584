.searchContainer{
    width: 100%;
    left:0;
    right:0;
    bottom: 0;
    margin-inline:auto;
    /* margin-top: 5.5rem; */
    /* margin-top: -10rem; */
    position:absolute;
    /* z-index:0; */
    bottom:0rem;
}

@media(max-width:1280px) {
    .searchContainer{
        margin-top: 1.5rem;
    }
}

.section1{
    width:80%;
    margin-inline:auto;
    display:flex;
    align-items: center;
    gap:0.5rem;
    /* flex-wrap: wrap; */
    
    background-color: #EEEEEE;
    /* background-color: black; */
    border:1px solid #E1E1E1;
    justify-content: space-between;
    padding: 1rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow:auto;
}

.maindiv{
    /* width:70%; */
    background-color: #EEEEEE;
    border:1px solid #E1E1E1;
    padding:1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:absolute;
    bottom:1rem;
    left:15%;
    right:15%;
    min-width: fit-content;
    z-index:1;
    border-radius: 10px;
    overflow:auto;
    gap:0.5rem;
}

/* @media(max-width:1030px) {
    .searchContainer{
        width:95%;
        bottom:13rem;
    }

    
} */
@media(max-width:850px) {
.section1{
    width:95%;
}
.section2{
    width:95%;
}
}
.section2{
    width:100%;
    margin-inline:auto;
    display:flex;
    align-items: center;
    background-color: #F2F2F2;
    /* border:1px solid #E1E1E1; */
    border-radius: 10px;
    justify-content: space-between;
    gap:0.2rem;
    /* overflow-x:auto; */
    
    /* overflow-inline: auto; */
    padding: 0.5rem;   
    /* overflow:auto; */
    /* flex-wrap: wrap; */
    /* z-index:10; */
}





.selectBox{
    color:#999999;
    cursor:pointer;
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:19%;
    gap:2rem;
    background-color: #EEEEEE;
    border:1px solid #E1E1E1;
    padding:1rem;
    border-radius: 10px;
    min-width:fit-content;
    position:relative;
    z-index:0;
    
}
@media(max-width:1080px) {
    /* .searchContainer{
        bottom:3.3rem;
    } */
    .section2{
        flex-direction: column;
        align-items: flex-start;
        gap:1rem;
        width:100%;
        display: none;
    }
    .selectBox{
        width:100%;
    }
}
.optionBox{
    position:absolute;
   width:100%;
    top:100%;
    left:0;
    right:0;
    appearance: none;
    min-width:fit-content;
   
    border:none;
    outline:none;
    background:#EEEEEE;   
    border: 1px solid #E1E1E1; 
    border-radius: 10px;
    padding: 1rem;
    color:#999999;
    z-index:1;
     
}

/* .optionBox{
    
   width:19%;
   
    appearance: none;
    min-width:fit-content;
   
    border:none;
    outline:none;
    background:#EEEEEE;   
    border: 1px solid #E1E1E1; 
    border-radius: 10px;
    padding: 1rem;
    color:#999999;
    
     
} */

.arrowimgdiv{
    width:1rem;
    
}

.rotate180{
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    -moz-transform: rotate(180deg);    /* Firefox */
    -ms-transform: rotate(180deg);     /* Internet Explorer 9 */
    -o-transform: rotate(180deg);      /* Opera */
    transform: rotate(180deg);
    /* Optional: add transition for smooth rotation */
    /* transition: transform 0.5s ease-in-out; */
}





@media(max-width:850px) {
    .maindiv{
        bottom:0rem;
    }
}

@media(min-width:851px) and (max-width:1080px) {
    .maindiv{
        bottom:0rem;
    }
}

@media(max-width:915px) {
    .maindiv{
        left:5%;
        right:5%;
    }
}

.inputBox{
    min-width:70%;
    /* background-color: yellow;; */
}

@media(max-width:660px) {
    .inputBox{
        min-width:30%;
        /* background:black; */
    }
}

@media(min-width:661px) and (max-width:1000px){
    .inputBox{
        min-width:50%;
        /* background:black; */
    }
}

@media(max-width:600px) {
    .buttontext{
        display:none;
    }
}








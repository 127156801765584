.wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    min-height: 100vh;
  }
  
  
  .maindiv{
      
      flex:1;
      /* background-color: aqua; */
      
  }
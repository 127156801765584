.sidebarContainer{
  opacity: 1;
  /* transform: translateX(100%); */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;   
  font-family: Urbanist;
    position: fixed;
  top: 0;
  right:0;  
  height: 100vh;
  background-color: #E8E8E8;
  width: 25%;  
  min-width: fit-content;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  overflow-y: auto;
  z-index:1000;
  padding:2rem;
  color:black;
  display:flex;
  flex-direction: column; 
  gap:1rem; 
  cursor:pointer;
}

/* @media(max-width:450px) {
  .sidebarContainer{
    width:90%;
  }
} */

@media (max-width:500px) {
  .sidebarContainer{
    width:80%;
  }
}

@media(min-width:501px) and (max-width:860px) {
  .sidebarContainer{
    width:35%;
  }
}

@media(min-width:861px) and (max-width:1100px)  {
  .sidebarContainer{
    width:30%;
  }
}

.visible {
  opacity: 1;
  transform: translateX(0);
}

.hidden{
  opacity: 1;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out, transform 0.5s ease-in-out;  
}

.sections{
   display: flex;
   flex-direction: column;
   gap: 1rem;;
}

.lastdiv{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    width: 100%;
}
.listdiv{
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
    /* background-color: yellow; */
    margin-left: 1rem;;
    width:95%;
    display: flex;
    justify-content: space-between;
    padding-right: 0.5rem;
    align-items: center;
    cursor:pointer
}


hr{
    margin-top: -0.5rem;
}

.listsection1{
    display: flex;
    /* align-items: center; */
    gap: 1rem;

}

.sidebarContainer::-webkit-scrollbar {
    width: 0.4rem; /* Width of the scrollbar */
  }

  .sidebarContainer::-webkit-scrollbar-track {
    background: rgb(225, 224, 224); 
  }

  /* Handle of the scrollbar */
  .sidebarContainer::-webkit-scrollbar-thumb {
    background: #bcbcbc; 
    border-radius: 6px;
  }

  .rotate180 {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out; 
  }
 
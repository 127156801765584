.filtersdiv{
    display: flex;
    gap:0.5rem;
    flex-wrap: wrap;
    margin-top: 1rem;
    width:90%;
    align-items: start;
}

.filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:0.5rem;
    border: 1px solid #EBECF0;
border-radius: 999px;
padding: 0.5rem;
width: fit-content;
color: #42526E;
font-size: 0.8rem;   
cursor:pointer; 
}

.imgdiv{
    cursor: pointer;
   
}
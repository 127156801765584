.primary {
  background-color: #7065f0;
  color: white;
  padding: 0.8rem 2.5rem;
  border-radius: 10px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  border: none;
  font-weight: 550;
  font-size: 0.9rem;
  cursor:pointer;
}
@media(max-width:700px) {
  .primary {
  padding:0.8rem 1.5rem;
  }
}
.primary2 {
  background-color: #7065f0;
  color: white;
  padding: 0.8rem 2.5rem;
  border-radius: 10px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  border: none;
  /* font-weight: 550; */
  font-size: 0.9rem;
  cursor:pointer;
}



.secondary {
  background: #7065f01a;
  color: #7065f0;
  padding: 0.7rem;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  border: none;
  cursor:pointer;
}

.grey{
  background-color: #EAEAEA;
    border: 1px solid #E1E1E1;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    font-size: 0.9rem;  
    font-weight: 500;
    cursor:pointer;
    color:#0F0B3E;
}

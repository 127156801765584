.container {
    width: 100%;
    padding: 2rem 0;
    color: #0F0B3E;
    border-bottom: 1px solid #E3E3E3;
  }
  
  .sectionTitle {
    font-size: 1.5rem;
    font-weight: 650;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;
  }
  
  .sectionTitle::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: #7065f0;
    border-radius: 2px;
  }
  
  .loadingState, .emptyState {
    padding: 3rem;
    text-align: center;
    color: #666;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
  }
  
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(112, 101, 240, 0.3);
    border-radius: 50%;
    border-top-color: #7065f0;
    animation: spin 1s ease-in-out infinite;
    margin: 0 auto 1rem;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .keyMetricsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 1200px) {
    .keyMetricsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .keyMetricsGrid {
      grid-template-columns: 1fr;
    }
  }
  
  .metricCard {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .metricCard:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .metricTitle {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .metricValue {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0F0B3E;
    margin-bottom: 0.75rem;
  }
  
  .metricChange {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
  
  .positive {
    color: #27ae60;
  }
  
  .negative {
    color: #e74c3c;
  }
  
  .metricInfo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85rem;
    color: #666;
  }
  
  .returnMetricsContainer {
    background-color: #f8f9fa;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .returnMetricsContainer h4 {
    font-size: 1.1rem;
    margin-top: 0;
    margin-bottom: 1.25rem;
    color: #0F0B3E;
  }
  
  .returnMetricsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
  }
  
  @media (max-width: 1200px) {
    .returnMetricsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .returnMetricsGrid {
      grid-template-columns: 1fr;
    }
  }
  
  .returnMetric {
    text-align: center;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .returnMetricLabel {
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .returnMetricValue {
    font-size: 1.25rem;
    font-weight: 600;
    color: #7065f0;
  }
  
  .chartContainer {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .chartControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .chartControls {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }
  }
  
  .chartViewSelector, .timeframeSelector {
    display: flex;
    gap: 0.5rem;
  }
  
  .viewButton, .timeframeButton {
    padding: 0.5rem 1rem;
    background-color: #f8f9fa;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #666;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .viewButton:hover, .timeframeButton:hover {
    background-color: #f1f3f5;
  }
  
  .viewButton.active, .timeframeButton.active {
    background-color: #7065f0;
    color: white;
    border-color: #7065f0;
  }
  
  .chartHeader {
    margin-bottom: 1rem;
  }
  
  .chartHeader h4 {
    font-size: 1.1rem;
    margin: 0;
    color: #0F0B3E;
  }
  
  .chart {
    width: 100%;
    margin-bottom: 1rem;
    height: 300px;
  }
  
  .chartNote {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85rem;
    color: #666;
    font-style: italic;
  }
  
  .analyticsSplitContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 992px) {
    .analyticsSplitContainer {
      grid-template-columns: 1fr;
    }
  }
  
  .expenseBreakdown, .personalInvestment {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .expenseBreakdown h4, .personalInvestment h4 {
    font-size: 1.1rem;
    margin-top: 0;
    margin-bottom: 1.25rem;
    color: #0F0B3E;
  }
  
  .pieChartContainer {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
  
  .simplePieChart {
    width: 100%;
  }
  
  .pieChartSegment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
  }
  
  .pieChartLabel {
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  }
  
  .personalStats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
  }
  
  @media (max-width: 576px) {
    .personalStats {
      grid-template-columns: 1fr;
    }
  }
  
  .personalStatItem {
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .personalStatLabel {
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .personalStatValue {
    font-size: 1.15rem;
    font-weight: 600;
    color: #0F0B3E;
  }
  
  .loginPrompt {
    text-align: center;
    padding: 2rem 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    color: #666;
  }
  
  .loginPrompt p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
  }
  
  .loginPrompt a {
    color: #7065f0;
    font-weight: 500;
    text-decoration: none;
  }
  
  .loginPrompt a:hover {
    text-decoration: underline;
  }
  
  .disclaimerNote {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    font-size: 0.85rem;
    color: #666;
  }
  
  .disclaimerNote p {
    margin: 0;
    line-height: 1.5;
  }
  
  /* Custom chart styles */
  .simplifiedChart {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 1rem;
  }
  
  .chartLegend {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .legendItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
    color: #666;
  }
  
  .legendColor {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
  
  .chartMaxValue {
    font-size: 0.8rem;
    color: #666;
    text-align: right;
  }
  
  .barsContainer {
    display: flex;
    height: 220px;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 30px;
    position: relative;
  }
  
  .barGroup {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
  }
  
  .barInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    width: 100%;
    max-width: 80px;
  }
  
  .barItem {
    width: 15px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    margin: 0 2px;
  }
  
  .bar {
    width: 100%;
    transition: height 0.3s ease;
    border-radius: 3px 3px 0 0;
  }
  
  .barValue {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;
    color: #666;
    white-space: nowrap;
  }
  
  .barLabel {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
    color: #666;
    white-space: nowrap;
  }
  
  .projectedLabel {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.7rem;
    color: #a29bfa;
    font-style: italic;
  }
  
  .lineChartContainer {
    height: 220px;
    position: relative;
    margin-top: 20px;
    padding-bottom: 30px;
  }
  
  .lineChartPoint {
    position: absolute;
    transform: translate(-50%, 50%);
  }
  
  .lineChartDot {
    width: 8px;
    height: 8px;
    background-color: #7065f0;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .lineChartValue {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;
    color: #666;
    white-space: nowrap;
  }
  
  .lineChartLabel {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
    color: #666;
    white-space: nowrap;
  }
  
  .lineChartSvg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .lineChartPath {
    fill: none;
    stroke: #7065f0;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .noChartData {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;
    gap: 1rem;
  }
  
  .noChartData p {
    margin: 0;
  }
.maindiv{
    width:100%;
    /* background-color: rgb(248, 248, 255); */
    background-image: url('./backimg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-inline:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:5rem;
    padding: 4rem;
    margin-top: 3rem;;

}

.introdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
}

.para{
    color:#525252;
    font-size: 0.9rem;
    font-weight: 550;
    text-align: center;
}

.heading{
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}

.griddiv{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap:5rem;
    row-gap: 3rem;
}

@media(max-width:399px) {
    .griddiv{
        grid-template-columns: repeat(1,1fr);
        
    }
}

@media(min-width:400px) and (max-width:870px) {
    .griddiv{
        grid-template-columns: repeat(2,1fr);
    }
}

.cardsdivp{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.cardtitledivp{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap:0.5rem; */
}

.title1 {
    color:#37393F;
    font-size: 1.2rem;
    font-weight: 700;
}

.title2 {
    color:#37393F;
    font-size: 1.2rem;
    font-weight: 700;
}

.imgdiv{
    
    padding:0.8rem;
    border-radius: 50%;
    width:3.5rem;
    height:3.5rem;
}
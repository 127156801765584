
.maindiv{
    display: flex;
    flex-direction: row;
}

.bannerimage{
    border-radius: 20px, 200px, 200px, 20px;
    background-color: rgba(112, 101, 240, 0.06);
    width: 50%;
    height: 100%;
    
}

.modaloverlay{

    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* backdrop-filter: blur(100px); */
}

.circle{
    background: #7065F0;
    height: 100px;
    width: 100px;
    position: absolute;
    border-radius: 50%;
    top: 25%;
    left: 33.5%;
    
}

.modalcontent {
    /* background: #fff; */
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 0 4px rgba(112, 101, 240, 1);
    /* border-color: #7065F0; */
    max-width: 400px;
    width: 100%;
    text-align: center;
    backdrop-filter: blur(60px);

  }

.submit-button{
    width: 100%;
    background-color: rgba(112, 101, 240, 1);
    color: white;
    padding: 1em;
    border-radius: 8px;
    border: 0px;
    margin-bottom: 1em;
  }

.change-number{
        width: 100%;
        background-color:#9B9CA1;
        color: white;
        padding: 1em;
        border-radius: 8px;
        border: 0px;
        margin-bottom: 1em;

}
.box{
    display: flex ;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    height: 50px;
}

.input-box{
    /* background-color: aqua; */
    width: 30px;
    height: 30px;
    border-radius: 5px ;
    border-width: 0.5px;
    border-color: rgb(215, 210, 210);
    font-size: 18px;
    font-weight: lighter;
}

.link{
    color: #7065F0;
}

.heading{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 300;
    color: black;
}

.description{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #9B9CA1;
    font-size: small;
}
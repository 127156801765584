.tenanthightlightsgriddiv{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1.5rem;
}

@media(max-width:600px) {
    .tenanthightlightsgriddiv{
        grid-template-columns: repeat(2,1fr);
    }
}
.toggleContainer{
    display:flex;
    justify-content: center;
    gap:0.5rem;
    padding:0.5rem;    
    overflow:auto;
    width: 100%;
    margin-top: 4rem;
    background-color: #F6F6F6;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
}

.option{
    color:#0F0B3E;
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 550;
    border:1px solid #E1E1E1;
    padding-block: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   
    width:15rem
}

.option.active {
    color: white;
    background-color:  #7065f0;
  }
.wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    min-height: 100vh;
  }
  
  
  .maindiv{
      width:85%;
      padding: 8rem 0 5rem 0;
    
      margin-inline: auto;
      color:#0F0B3E;
      display:flex;
      justify-content: space-between; 
      gap:2rem;    
      /* align-items: center; */
      flex:1;
      /* background-color: aqua; */
      
  }
  @media(max-width:1024px) {
    .maindiv{
      flex-direction: column;
      align-items: center;
      gap:2rem;
    }
  }
  @media(min-width:1800px) {
    .maindiv{
      width:60%;    
   }
  }

  .section1{
    width:35%;
    /* background-color: red; */
    border-right: 1px solid rgba(0, 0, 0, 0.13);;
    /* background-color: yellow; */
    padding-inline: 2rem;
    overflow:auto;
    /* height:80vh; */
    
  }

  .section2{
    min-width:60%;
    /* background-color: green; */
    /* height:100vh; */
  }

  @media(max-width:1024px) {
    .section1{
      width:100%;
      border-right:none;
    }
    .section2{
      width:100%;
    }
  }

  .dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
  }

  

  .section1Container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .section2fleximgdivp{
    display: flex;
    width:100%;
    gap:1rem;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    /* overflow:auto; */
    
  }

  .section2fleximgdivp::-webkit-scrollbar {
    display:none;
  }


  .section2gridimgdivp{
    display: grid;
    width:100%;
    grid-template-columns: repeat(3,1fr);
    gap:1rem;
    /* align-items: center; */
    /* overflow:auto; */
  }

  @media(max-width:400px) {
    .section2gridimgdivp{
      grid-template-columns: repeat(1,1fr);
    }
  }

  @media (min-width: 401px) and (max-width: 600px) {
    .section2gridimgdivp {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .section2Container{
    display: flex;
    flex-direction: column;
    width:100%;
    gap: 3rem;
    margin-top: 2rem;;
  }

  .toggleContainer{
    display:flex;
    /* justify-content: space-between; */
    gap:3rem;
    /* flex-wrap: wrap; */
/* background-color: aqua; */
/* margin-left: 20%; */
    /* gap:4rem; */
    overflow:auto;
    width: 80%;
}

.option{
    color:#3F3D56;
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 550;
   
}

.option.active {
    color: #7065f0;
    border-bottom: 3px solid #7065f0;
  }


  .section1imgdiv{
    width:100%;
    aspect-ratio: 1;
  }
.maindiv{
    width:85%;
    padding-top: 10rem;
    /* background-color: yellow; */
    margin-inline: auto;
    color:#0F0B3E;
    /* color:red; */
}
@media(min-width:1800px) {
 
  .maindiv{
    width:60%;
  }
}

/* @media(min-width:1800px) {
  .maindiv{
    width:50%;
  }
} */

.toggleContainer{
    display:flex;
    /* justify-content: space-between; */
    gap:3rem;
    /* flex-wrap: wrap; */
/* background-color: aqua; */
/* margin-left: 20%; */
    /* gap:4rem; */
    overflow:auto;
    width: 80%;
}

.option{
    color:#3F3D56;
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 550;
   
}

.option.active {
    color: #7065f0;
    border-bottom: 3px solid #7065f0;
  }

  .section1{
    padding:2rem 0 4rem;
  }

  .section2{
    padding-block: 2rem;
  }

  .section2main{
  
    display: flex;
    flex-direction: column;
    gap:2rem;
    align-items: center;
  }

  .section2intro{
    display:flex;
    justify-content: space-between;
    gap:2rem;
    flex-wrap: wrap;
    /* background-color: black; */
    width: 100%;
  }

  .section2heading{
    display: flex;
    align-items: center;
    gap:1rem;
    flex-wrap:wrap
  }

  .section2buttondiv{
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
  }

  .section2imgdivp{
    width: 100%;
    padding:2rem;
    background-color: #F6F6F6;
    border-radius: 10px;
    border:1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    overflow:"auto";
  }
  .section2imgdiv1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:1rem;
    overflow:auto;
    padding:1rem;
    width:100%;
    background-color: #E3E3E3;
    border-radius: 10px;
    overflow: auto;
    /* overflow:hidden; */
    
  }

  .rowimg{
    width:10%;
  }

.section2imgdiv2{
    display: flex;
    align-items: center;
    gap:1rem;
    width:100%;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.section2imgdiv3{
 display: flex;
 align-items: center;
 width:100%;
 justify-content: space-between;
 gap:3rem;
 flex-wrap: wrap;
 
}

/* .section2descriptionp{
    display: flex;
    
    justify-content: space-between;
    gap:1rem;
    
    width:100%;
    
} */

.section2descriptionp{
  /* display: grid;
  grid-template-columns: repeat(2,1fr); */
  display: flex;
  justify-content:space-between;
  
  gap:1rem;
  width:100%;
  margin-inline: auto;
  /* background-color: red; */
  /* overflow: auto; */
}

@media(max-width:700px){
  .section2imgdiv2{
    flex-direction: column;
}
}

@media(max-width:1000px){
  .section2descriptionp{
    /* width:100%; */
    margin-inline: auto;
    /* grid-template-columns: repeat(1,1fr); */
    flex-direction: column;
    align-items: center;
  }

  
}

.section2description{
    border:1px solid #E3E3E3;
    border-radius: 10px;
    width:100%;
    /* height:10rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding:2rem 2rem 1rem;
    /* margin-top: 2rem;; */
    overflow:auto;
    /* background-color: yellow; */
}

.section2features{
    /* border:1px solid #E3E3E3; */
    border-radius: 10px;
    width:100%;
    /* height:10rem; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
    overflow:auto;
}
.section2features1{
    border:1px solid #E3E3E3;
    border-radius: 10px;
    width:100%;
    /* height:10rem; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding:2rem 2rem;
    overflow:auto;
}
.section2features1grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 3rem;
}
/* @media(max-width:500px) {
  .section2features1grid{
    grid-template-columns: repeat(1,1fr);
    
  }
} */
.section2features2{
    border:1px solid #E3E3E3;
    border-radius: 10px;
    width:100%;
    /* height:10rem; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding:1rem 2rem;
    overflow:auto;
}
.section2features3{
    border:1px solid #E3E3E3;
    border-radius: 10px;
    width:100%;
    /* height:10rem; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding:2rem 2rem;
    overflow:auto;
}

.section3{
    padding: 4rem 0 2rem 0;
}
  
.section4{
    padding: 2rem 0 2rem 0;
    
}
.section5{
    padding: 2rem 0 2rem 0;
}

.section6{
  padding: 2rem 0 4rem 0;
  border-bottom: 1px solid #E3E3E3;
  
}
.section7{
  padding: 2rem 0 2rem 0;
  
  
}
.section8{
  padding: 2rem 0 2rem 0;
  
}
.section9{
  padding: 2rem 0 2rem 0;
  
}
.section10{
  padding: 2rem 0 2rem 0;
  
}
.section11{
  padding: 2rem 0 2rem 0;
  
}

.inputdiv{
  border:1px solid grey;
  border-radius: 10px;
  padding: 1rem;
}
  

.FeaturedPropertyContainer {
  /* padding-inline: 5.5rem; */
  padding-top:3rem;
  overflow:auto;
  width:100%;
  /* margin-left:3rem; */
  /* padding-inline:4rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  
  /* justify-content: space-between; */
  gap:2rem;
  /* margin-inline: 4rem; */
}

h4{
  
  color:#0F0B3E;
  /* color:green; */
}

.slider{
  /* background-color: #F1F2F2; */
  /* background-color: red; */
  height:15px;
  border-radius: 5px;
  position: relative;
  width:100%;
  
}

.progress1{
  position: absolute;
  height:100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  
  background-color: #4A77C3;
}
.progress2{
  position: absolute;
  height:100%;
  
  background-color: #689AEF;
}
.progress3{
  position: absolute;
  height:100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  
  background-color: #F1F2F2;
}


.section9box{
  border:1px solid #E3E3E3;
  border-radius: 10px;
  width:100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding:1rem;
  overflow:auto;
}

.section2grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  width:100%;
  overflow:auto;
  /* gap:1rem; */
  
}

.profilebuttondivp{
  display: flex;
  align-items: center;
  gap: 5rem;
  background-color: rgba(112, 101, 240, 0.12);
  padding: 0.6rem 1rem 0.6rem 0.6rem;
  border-radius: 10px;
  flex-wrap: wrap;  
}

@media(max-width:506px) {
  .profilebuttondivp{
    gap:1rem;
  }
}


.desc{
  font-weight: 400;
  font-size: 0.9em;
  width: 70%;
  line-height: 1.2rem;
  
  width: 80%;
}

@media(max-width:800px) {
  .desc{
    width:100%;
  }
}





.grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:1rem;
    margin-top: 2rem;
}

@media(max-width:800px) {
    .grid{
        grid-template-columns: repeat(1,1fr);
    }
}

.card{
    background-color: #F6F6F6;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    padding:2rem;    
    gap:2rem;
}

.introdiv{
    display:flex;
    align-items:center;

    gap:1rem;
}

.imgdiv{
    width:4rem;

}

.heading{
    font-weight: 650;
    font-size: 1.3rem;
    
}

.paradivp{
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.para{
    font-weight: 550;
    font-size: 0.9rem;
}

.desc{
    font-weight: 400;
    font-size: 0.9em;
    width: 70%;
    line-height: 1.2rem;
    
    width: 80%;
  }
  
  @media(max-width:800px) {
    .desc{
      width:100%;
    }
  }
.maindiv{
    /* width:70%; */
    background-color: #EEEEEE;
    border:1px solid #E1E1E1;
    padding:1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:absolute;
    bottom:1rem;
    left:15%;
    right:15%;
    min-width: fit-content;
    z-index:1;
    border-radius: 10px;
    overflow:auto;
    gap:0.5rem;
}



@media(max-width:850px) {
    .maindiv{
        bottom:0rem;
    }
}

@media(min-width:851px) and (max-width:1150px) {
    .maindiv{
        bottom:0rem;
    }
}

@media(max-width:915px) {
    .maindiv{
        left:5%;
        right:5%;
    }
}

.inputBox{
    min-width:70%;
    /* background-color: yellow;; */
}

@media(max-width:660px) {
    .inputBox{
        min-width:30%;
        /* background:black; */
    }
}

@media(max-width:600px) {
    .buttontext{
        display:none;
    }
}




